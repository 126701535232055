<template>
  <b-card body-class="p-0 mb-2" header-tag="header" :header-class="['card-row', {rounded: !this.expand}]">
    <template #header>
      <b-container fluid class="px-1 py-2">
        <b-row class="clickable" @click.self="expand = !expand">
          <b-col cols="4" class="clickable" @click.self="expand = !expand">
            {{ template.displayname }}
            <b-icon-caret-down-fill :flip-v="expand" @click="expand = !expand"/>
          </b-col>
          <b-col cols="6" class="ml-auto clickable" @click.self="expand = !expand">
            <BadgeCounterTemplate :template="template"/>
          </b-col>
          <b-col cols="auto" class="clickable" @click.self="expand = !expand">
            <b-icon-pencil-square :id="'tt-editTemplate' + template.id" class="clickable" @click="editTemplate(template)"/>
            <b-tooltip :target="'tt-editTemplate' + template.id" triggers="hover">Bearbeiten</b-tooltip>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <TransitionExpand>
      <TemplateDetails v-if="expand" :template="template"/>
    </TransitionExpand>
  </b-card>
</template>

<script>
import TransitionExpand from "@/components/utility/TransitionExpand.vue";
import TemplateDetails from "@/components/fg-software/templates/TemplateDetails.vue";
import BadgeCounterTemplate from "@/components/fg-software/badgeCounter/BadgeCounterTemplate.vue";

export default {
  name: "SoftwareRow",
  props: ['template', 'groupId'],
  components: {
    TemplateDetails,
    TransitionExpand,
    BadgeCounterTemplate
  },
  data() {
    return {
      expand: this.extended
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    editTemplate(data) {
      data.groupId = this.groupId
      this.$emit('edit-template', data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

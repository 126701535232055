<template>
  <div>
    <b-row>
      <b-col cols="2"/>
      <b-col cols="8" style="font-size: 14px">
        <SubHeader class="mb-2">
          <GroupFilter class="mt-2" groupContent="template"  @search-changed="setSearch"
                       @filter-options="setFilterOptions" @add-group="addGroup" @add-group-content="addTemplate"/>
        </SubHeader>
        <div v-if="groupsFiltered">
          <div v-for="group in groupsFiltered" :key="group.id" class="mt-3">
            <Group :group="group" @add-group-content="addTemplate" @edit-group="editGroup">
              <template v-slot:groupInfo>
                <b-row v-for="(value, key) in groupInfo(group)" :key="key">
                  <b-col class="text-left">
                    {{ key }}
                  </b-col>
                  <b-col class="text-right">
                    <span v-if="key === 'Lizenzportal'">
                      <a v-if="value" :href="value" target="_blank">{{ value | url }}</a>
                      <span v-else>-</span>
                    </span>
                    <span v-else>
                      <span v-if="value">{{ value }}</span>
                      <span v-else>-</span>
                    </span>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:content>
                <div v-if="group.templates.length > 0">
                  <div v-for="template in group.templates" :key="template.id">
                    <TemplateRow :template="template" :groupId="group.id" @edit-template="editTemplate"/>
                  </div>
                </div>
                <b-row v-else cols="1">
                  <b-col>
                    <h6 class="text-secondary text-center">Keine Templates vorhanden</h6>
                  </b-col>
                </b-row>
              </template>
            </Group>
          </div>
        </div>
        <b-row v-else cols="1">
          <b-col>
            <h5 class="text-secondary text-center mt-5">Keine Daten vorhanden</h5>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <LicenseGroupModal :method="method" :data="groupData" @refresh="refresh"/>
    <TemplateModal :method="method" :data="templateData" :groupId="groupId" @refresh="refresh"/>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import {postRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import Group from "@/components/fg-software/Group.vue";
import TemplateRow from "@/components/fg-software/templates/TemplateRow.vue";
import GroupFilter from "@/components/fg-software/GroupFilter.vue";
import TemplateModal from "@/components/fg-software/templates/TemplateModal.vue";
import LicenseGroupModal from "@/components/fg-software/software/modals/LicenseGroupModal";

export default {
  name: "TemplateManage",
  components: {
    Group,
    SubHeader,
    ScrollToTop,
    TemplateRow,
    GroupFilter,
    TemplateModal,
    LicenseGroupModal
  },
  data() {
    return {
      search: {
        groupSearch: '',
        contentSearch: ''
      },
      groups: [],
      method: null,
      filterOptions: null,
      groupId: null,
      groupData: null,
      templateData: null,
      commandSyncData: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async refresh() {
      this.setLoadingState(true)
      await this.getTemplates()
      this.setLoadingState(false)
    },
    async loadData() {
      this.setLoadingState(true)
      await this.getTemplates()
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    },
    async getTemplates() {
      let data
      if(!this.filterOptions) {
        data = {
          inactive: false
        }
      } else {
        data = this.filterOptions
      }
      this.groups = []
      await postRequest('templates/fg/all', data, this, null)
          .then((response) => {
            this.groups = response.data.groups
          })
    },
    groupInfo(group) {
      if(group) {
        return {
          'Status': !group['inactive'] ? 'Aktiv' : 'Nicht aktiv',
          'Lizenzportal': group['license_portal'],
          'Lizenzserver': group['license_server'],
          'Lizenzart': group['license_method'],
          'Lizenzabfrage': group['license_manage'],
          'Notiz': group['note']
        }
      }
      return null
    },
    setSearch(data) {
      this.search = data
    },
    setFilterOptions(data) {
      this.filterOptions = data
      this.refresh()
    },
    addGroup() {
      this.method = 'add'
      this.$bvModal.show('licenseGroupModal')
    },
    addTemplate(data) {
      this.method = 'add'
      this.groupId = data
      this.$bvModal.show('templateModal')
    },
    editGroup(data) {
      this.method = 'edit'
      this.groupData = data
      this.$bvModal.show('licenseGroupModal')
    },
    editTemplate(data) {
      this.method = 'edit'
      this.templateData = data
      this.$bvModal.show('templateModal')
    },
  },
  computed: {
    groupsFiltered() {
      let groupList = _.filter(this.groups, (group) => {
        return group.displayname.toLowerCase().includes(this.search.groupSearch.toLowerCase())
      })
      return _.filter(groupList, (group) => {
        if(group.templates.length > 0) {
          return _.filter(group.templates, (template) => {
            return template.displayname.toLowerCase().includes(this.search.contentSearch.toLowerCase())
          }).length > 0
        }
      })
    }
  },
  created() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <b-modal id="templateModal" :title="title" size="xl" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="3">
          <label>Template ID:</label>
          <b-form-input v-model="templateId" placeholder="Template ID..." trim/>
        </b-col>
        <b-col cols="3">
          <label>Templatename:</label>
          <b-form-input v-model="displayname" placeholder="Templatename..." trim/>
        </b-col>
        <b-col cols="2">
          <label>Inaktiv: <b-icon-question-circle-fill id="tt-active" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="inactive" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-active" triggers="hover">Inaktives Template</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="3">
          <label>Gruppe:</label>
          <b-form-select v-model="groupSelect.selected" :options="groupSelect.options">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Gruppe auswählen --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="3">
          <label>Aktiv ab:</label>
          <b-input-group>
            <b-form-input v-model="activeSince" @input="inputChange" type="text"/>
            <b-input-group-append>
              <b-form-datepicker v-model="datepickerDate" @input="datepickerChange" button-only dropleft locale="de"/>
            </b-input-group-append>
          </b-input-group>
        </b-col>

      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Kurse:</label>
          <b-form-textarea v-model="courses" rows="4"/>
        </b-col>
      </b-form-row>
    </b-form>
    <b-form-row class="mt-2">
      <b-col>
        <label>Notiz:</label>
        <b-form-textarea v-model="note" rows="4"/>
      </b-col>
    </b-form-row>
    <template #modal-footer="{ cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import {getRequest, putRequest} from "@/modules/requests";

export default {
  name: "TemplateModal",
  props: ['method', 'data', 'groupId'],
  data() {
    return {
      id: null,
      templateId: 0,
      courses: '',
      note: '',
      displayname: '',
      inactive: false,
      activeSince: dayjs().format('DD.MM.YYYY'),
      datepickerDate: dayjs().format('YYYY-MM-DD'),
      groupSelect: {
        selected: null,
        options: []
      },
      loadingOkButton: false
    }
  },
  watch: {
    data() {
      this.onShow()
    },
    method() {
      this.onShow()
    },
    groupId() {
      this.onShow()
    }
  },
  methods: {
    ok() {
      switch(this.method) {
        case 'edit':
          this.updateTemplate();
          break;
        case 'add':
          this.addTemplate();
          break;
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    inputChange(event) {
      if(dayjs(event, 'DD.MM.YYYY', true).isValid()) {
        this.datepickerDate = dayjs(event, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
      }
    },
    datepickerChange(event) {
      if(dayjs(event, 'YYYY-MM-DD', true).isValid()) {
        this.activeSince = dayjs(event).format('DD.MM.YYYY')
      }
    },
    async onShow() {
      await this.getGroups()
      if(this.method === 'edit' && this.data) {
        this.id = this.data.id
        this.templateId = this.data.template_id
        this.courses = this.data.courses
        this.note = this.data.note
        this.displayname = this.data.displayname
        this.inactive = this.data.inactive
        this.activeSince = (this.data.active_at) ? dayjs(this.data.active_at).format('DD.MM.YYYY') : dayjs().format('DD.MM.YYYY')
        this.datepickerDate = (this.data.active_at) ? dayjs(this.data.active_at).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
        this.groupSelect.selected = this.data.groupId
      }
      else {
        this.id = null
        this.templateId = 0
        this.displayname = ''
        this.inactive = false
        this.courses = ''
        this.note = ''
        this.activeSince = dayjs().format('DD.MM.YYYY')
        this.datepickerDate = dayjs().format('YYYY-MM-DD')
        this.groupSelect.selected = this.groupId
      }
    },
    async getGroups() {
      await getRequest('licenses/fg/license-groups', null, this)
          .then((response) => {
            this.groupSelect.options = []
            response.data.forEach(element => {
              this.groupSelect.options.push({
                value: element.id,
                text: element.displayname
              })
            })
          })
    },
    async addTemplate() {
      this.loadingOkButton = true
      let data = {
        template_id: this.templateId,
        displayname: this.displayname,
        courses: this.courses,
        note: this.note,
        inactive: this.inactive,
        active_at: this.activeSince,
        group_id: this.groupSelect.selected
      }
      await putRequest('templates/fg/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('templateModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateTemplate() {
      this.loadingOkButton = true
      let data = {
        id: this.id,
        template_id: this.templateId,
        displayname: this.displayname,
        courses: this.courses,
        note: this.note,
        inactive: this.inactive,
        active_at: this.activeSince,
        group_id: this.groupSelect.selected
      }
      await putRequest('templates/fg/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('templateModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'white'
    },
    title() {
      if(this.method === 'edit') {
        return 'Template ändern'
      }
      return 'Template hinzufügen'
    },
    disabledButton() {
      return !(this.displayname && this.templateId && this.courses && this.activeSince && this.groupSelect.selected)
    },
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
